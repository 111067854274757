@import "@finn-no/troika-css-dev/tools/t-display.css";

/**


        Display helper utilities


*/
.u-display-none {
    display: none;
}

.u-display-block {
    display: block;
}

.u-display-inline {
    display: inline;
}

.u-display-inline-block {
    display: inline-block;
}

.u-display-table {
    /* Rewrite to abstracts instead? */
    @include displayTable;
}

.u-display-cell {
    /* Rewrite to abstracts instead?*/
    @include displayCell;
}

.u-print-only {
    @include printOnly;
}

.u-no-print {
    @include noPrint;
}

.u-screen-reader-only {
    @include screenReaderOnly;
}

@media (prefers-reduced-motion: reduce) {
    .u-prefers-motion-only {
        /* Usually combined with .u-screen-reader-only, first used in react-bottom-sheet */
        display: none;
    }
}

.u-hide {
    @media (--max480) {
        &-lt480 {
            display: none !important;
        }
    }

    @media (--min480) {
        &-gt480 {
            display: none !important;
        }
    }

    @media (--max640) {
        &-lt640 {
            display: none !important;
        }
    }

    @media (--min640) {
        &-gt640 {
            display: none !important;
        }
    }

    @media (--max768) {
        &-lt768 {
            display: none !important;
        }
    }

    @media (--min768) {
        &-gt768 {
            display: none !important;
        }
    }

    @media (--max900) {
        &-lt900 {
            display: none !important;
        }
    }

    @media (--min900) {
        &-gt900 {
            display: none !important;
        }
    }

    @media (--max1235) {
        &-lt1235 {
            display: none !important;
        }
    }

    @media (--min1235) {
        &-gt1235 {
            display: none !important;
        }
    }
}
