/**


        Opacity helper utilities


*/

.u-opacity {
    &025 {
        opacity: 0.25;
    }
    &050 {
        opacity: 0.5;
    }
    &075 {
        opacity: 0.75;
    }
}
