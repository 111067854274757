@import "@finn-no/troika-css-dev/settings/s-fonts.css";

.u- {
    &t1,
    &t2,
    &t3,
    &t4,
    &t5 {
        font-weight: 300; /* firefox translates normal to 400 other browsers 300 */
    }

    &t1 {
        font-size: $t1-size--normal;
        line-height: $t1-lineheight--normal;
        font-weight: bold;
    }

    &t2 {
        font-size: $t2-size--normal;
        line-height: $t2-lineheight--normal;
        font-weight: bold;
    }

    &t3 {
        font-size: $t3-size--normal;
        line-height: $t3-lineheight--normal;
        font-weight: bold;
    }

    &t4 {
        font-size: $t4-size--normal;
        line-height: $t4-lineheight--normal;
        font-weight: bold;
    }

    &t5 {
        font-size: $t5-size--normal;
        line-height: $t5-lineheight--normal;
        font-weight: bold;
    }

    &b1 {
        font-size: $base-size--normal;
        line-height: $base-lineheight--normal;
        font-weight: normal;
    }

    &d1 {
        font-size: $d1-size--normal;
        line-height: $d1-lineheight--normal;
        font-weight: normal;
    }

    &caption {
        font-size: $caption-size--normal;
        line-height: $caption-lineheight--normal;
        font-weight: normal;
    }

    &normal {
        font-weight: normal;
    }

    &italics {
        font-style: italic;
    }

    &strong {
        font-weight: bold;
    }

    &strikethrough {
        text-decoration: line-through;
    }

    &capitalize {
        display: inline-block;

        &:first-letter {
            text-transform: capitalize;
        }
    }

    &uppercase {
        text-transform: uppercase;
    }
}
