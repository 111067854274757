@import "@finn-no/troika-css-dev/tools/t-clearfix.css";

/**


        Clearfix helper utilities


*/

.u-cb {
    clear: both;
}

.u-cf {
    @include clear-fix;
}
