/**


        Spacing helper utilities


*/
/**



    Usage: {short for rule}{short for direction}{size}

    {short for rule}
    p - padding
    m - margin

    {short for direction}
    t - top
    r - right
    b - bottom
    l - left
    v - vertical
    h - horizontal
    a - all

    {size}
    [0, 2, 4, 8, 16, 32, 64, 128, a (auto)]


*/

@each $space in 0, 2, 4, 8, 16, 24, 32, 64, 128 {
    .u-pa$(space) {
        /* prettier-ignore */
        padding: $(space)px !important;
    }
    .u-ma$(space) {
        /* prettier-ignore */
        margin: $(space)px !important;
    }
    .u-pv$(space) {
        /* prettier-ignore */
        padding-top: $(space)px !important;
        /* prettier-ignore */
        padding-bottom: $(space)px !important;
    }
    .u-mv$(space) {
        /* prettier-ignore */
        margin-top: $(space)px !important;
        /* prettier-ignore */
        margin-bottom: $(space)px !important;
    }
    .u-ph$(space) {
        /* prettier-ignore */
        padding-right: $(space)px !important;
        /* prettier-ignore */
        padding-left: $(space)px !important;
    }
    .u-mh$(space) {
        /* prettier-ignore */
        margin-right: $(space)px !important;
        /* prettier-ignore */
        margin-left: $(space)px !important;
    }
    .u-pt$(space) {
        /* prettier-ignore */
        padding-top: $(space)px !important;
    }
    .u-mt$(space) {
        /* prettier-ignore */
        margin-top: $(space)px !important;
    }
    .u-pr$(space) {
        /* prettier-ignore */
        padding-right: $(space)px !important;
    }
    .u-mr$(space) {
        /* prettier-ignore */
        margin-right: $(space)px !important;
    }
    .u-pb$(space) {
        /* prettier-ignore */
        padding-bottom: $(space)px !important;
    }
    .u-mb$(space) {
        /* prettier-ignore */
        margin-bottom: $(space)px !important;
    }
    .u-pl$(space) {
        /* prettier-ignore */
        padding-left: $(space)px !important;
    }
    .u-ml$(space) {
        /* prettier-ignore */
        margin-left: $(space)px !important;
    }
}
.u-maa {
    margin: auto;
}
.u-mva {
    margin-top: auto;
    margin-bottom: auto;
}
.u-mha {
    margin-right: auto;
    margin-left: auto;
}
.u-mta {
    margin-top: auto;
}
.u-mra {
    margin-right: auto;
}
.u-mba {
    margin-bottom: auto;
}
.u-mla {
    margin-left: auto;
}
