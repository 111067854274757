@import "@finn-no/troika-css-dev/settings/s-spacing.css";

/**


        Sizing helper utilities


*/

.u-fit-height {
    height: 100%;
}

.u-width-auto {
    width: auto;
}

.u-overflow {
    overflow: visible;
}

.u-overflow-hidden {
    overflow: hidden;
}

/* General Sizes (can be used directly on grid__unit's or on anything for a width) */
.u-size1of1 {
    float: none;
    width: $s1of1;
}

.u-size1of2 {
    width: $s1of2;
}

.u-size1of3 {
    width: $s1of3;
}

.u-size2of3 {
    width: $s2of3;
}

.u-size1of4 {
    width: $s1of4;
}

.u-size3of4 {
    width: $s3of4;
}

.u-size1of5 {
    width: $s1of5;
}

.u-size2of5 {
    width: $s2of5;
}

.u-size3of5 {
    width: $s3of5;
}

.u-size4of5 {
    width: $s4of5;
}

.u-size1of6 {
    width: $s1of6;
}

.u-size5of6 {
    width: $s5of6;
}

.u-size1of7 {
    width: $s1of7;
}

.u-size1of8 {
    width: $s1of8;
}

/* Magic sizes that collapse to 100% below 768px screens, probably to be phased out abstract grid setups (?) */
.u-r-size1of1,
.u-r-size1of2,
.u-r-size1of3,
.u-r-size2of3,
.u-r-size1of4,
.u-r-size3of4,
.u-r-size1of5,
.u-r-size2of5,
.u-r-size3of5,
.u-r-size4of5,
.u-r-size1of6,
.u-r-size5of6 {
    width: $s1of1;
}

@media (--min768) {
    .u-r-size1of1 {
        float: none;
    }
    .u-r-size1of2 {
        width: 50%;
    }
    .u-r-size1of3 {
        width: 33.33333%;
    }
    .u-r-size2of3 {
        width: 66.66666%;
    }
    .u-r-size1of4 {
        width: 25%;
    }
    .u-r-size3of4 {
        width: 75%;
    }
    .u-r-size1of5 {
        width: 20%;
    }
    .u-r-size2of5 {
        width: 40%;
    }
    .u-r-size3of5 {
        width: 60%;
    }
    .u-r-size4of5 {
        width: 80%;
    }
    .u-r-size1of6 {
        width: 16.6666666%;
    }
    .u-r-size5of6 {
        width: 83.3333333%;
    }
}
