/**


    Usage:
    .u-col-count{n}
    .u-col-count{n}from{breakpoint}
    .u-col-gap-{size}
    
    {n} number of columns
    1, 2, 3

    {breakpoints}
    480, 640, 768, 990
   
    {size} px space 
    0, 8, 16, 32


*/

.u-col- {
    &count1 {
        column-count: 1;
    }
    &count2 {
        column-count: 2;
    }
    &count3 {
        column-count: 3;
    }
}

@media (--min990) {
    .u-col- {
        &count1from990 {
            column-count: 1;
        }

        &count2from990 {
            column-count: 2;
        }

        &count3from990 {
            column-count: 3;
        }
    }
}

@media (--max990) {
    .u-col- {
        &count1upto990 {
            column-count: 1;
        }

        &count2upto990 {
            column-count: 2;
        }

        &count3upto990 {
            column-count: 3;
        }
    }
}

@media (--max768) {
    .u-col- {
        &count1upto768 {
            column-count: 1;
        }

        &count2upto768 {
            column-count: 2;
        }

        &count3upto768 {
            column-count: 3;
        }
    }
}

@media (--max640) {
    .u-col- {
        &count1upto640 {
            column-count: 1;
        }

        &count2upto640 {
            column-count: 2;
        }

        &count3upto640 {
            column-count: 3;
        }
    }
}

@media (--max480) {
    .u-col- {
        &count1upto480 {
            column-count: 1;
        }

        &count2upto480 {
            column-count: 2;
        }

        &count3upto480 {
            column-count: 3;
        }
    }
}

.u-col-gap- {
    &0 {
        column-gap: 0px;
    }
    &8 {
        column-gap: 8px;
    }
    &16 {
        column-gap: 16px;
    }
    &32 {
        column-gap: 32px;
    }
}
