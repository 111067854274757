/**


        Position helper utilities


*/

.u-text-right {
    text-align: right;

    @media (--max768) {
        &--lt768 {
            text-align: right;
        }
    }

    @media (--min768) {
        &--gt768 {
            text-align: right;
        }
    }
}

.u-text-left {
    text-align: left;

    @media (--max768) {
        &--lt768 {
            text-align: left;
        }
    }

    @media (--min768) {
        &--gt768 {
            text-align: left;
        }
    }
}

.u-text-center {
    text-align: center;

    @media (--max768) {
        &--lt768 {
            text-align: center;
        }
    }

    @media (--min768) {
        &--gt768 {
            text-align: center;
        }
    }
}

.u-valign-top {
    vertical-align: top;
}

.u-valign-middle {
    vertical-align: middle;
}

.u-valign-bottom {
    vertical-align: bottom;
}

.u-position-relative {
    position: relative;
}

.u-position-absolute {
    position: absolute;
}

.u-position-fixed {
    position: fixed;
}

.u-position-sticky {
    position: sticky;
    top: 50px;
}

.u-top {
    top: 0;
}

.u-bottom {
    bottom: 0;
}

.u-right {
    right: 0;
}

.u-left {
    left: 0;
}

.u-float-right {
    float: right;

    @media (--max768) {
        &--lt768 {
            float: right !important;
        }
    }

    @media (--min768) {
        &--gt768 {
            float: right !important;
        }
    }
}

.u-float-left {
    float: left;

    @media (--max768) {
        &--lt768 {
            float: left !important;
        }
    }

    @media (--min768) {
        &--gt768 {
            float: left !important;
        }
    }
}
