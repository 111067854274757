/* TODO: Check browser compatibility according to: http://caniuse.com/#feat=multicolumn */
/**


    Usage:
    .u-cols-{n}upto{breakpoint}
    .u-cols-{n}from{breakpoint}

    AND

    {n} number of columns
    1, 2, 3

    {breakpoints}
    480, 640, 768, 990


    Space between cols
    .u-cols--gap-small
    .u-cols--gap-medium
    .u-cols--gap-large


*/

.u-cols {
    &-1 {
        column-count: 1;
    }

    &-2 {
        column-count: 2;
    }

    &-3 {
        column-count: 3;
    }

    &-1from990,
    &-2from990,
    &-3from990,
    &-1upto990,
    &-2upto990,
    &-3upto990,
    &-1upto768,
    &-2upto768,
    &-3upto768,
    &-1upto640,
    &-2upto640,
    &-3upto640,
    &-1upto480,
    &-2upto480,
    &-3upto480 {
        break-inside: avoid;
        column-gap: 40px;
    }

    &--gap-small {
        column-gap: 8px;
    }

    &--gap-medium {
        column-gap: 16px;
    }

    &--gap-large {
        column-gap: 32px;
    }

    &-1from990 {
        @media (--min990) {
            column-count: 1;
        }
    }

    &-2from990 {
        @media (--min990) {
            column-count: 2;
        }
    }

    &-3from990 {
        @media (--min990) {
            column-count: 3;
        }
    }
}

@media (--max990) {
    .u-cols {
        &-1upto990 {
            column-count: 1;
        }

        &-2upto990 {
            column-count: 2;
        }

        &-3upto990 {
            column-count: 3;
        }
    }
}

@media (--max768) {
    .u-cols {
        &-1upto768 {
            column-count: 1;
        }

        &-2upto768 {
            column-count: 2;
        }

        &-3upto768 {
            column-count: 3;
        }
    }
}

@media (--max640) {
    .u-cols {
        &-1upto640 {
            column-count: 1;
        }

        &-2upto640 {
            column-count: 2;
        }

        &-3upto640 {
            column-count: 3;
        }
    }
}

@media (--max480) {
    .u-cols {
        &-1upto480 {
            column-count: 1;
        }

        &-2upto480 {
            column-count: 2;
        }

        &-3upto480 {
            column-count: 3;
        }
    }
}
