@import "@finn-no/troika-css-dev/settings/s-fonts.css";

/**



    Usage: {prefix}{color-name}

    [prefix]
    u-          color
    u-bg-       background-color
    u-border-   border-color
    u-focus-    an elements outline-color on focus state

    {color-name}
    any color from the palette



*/

@each $color in primary-blue, secondary-blue, toothpaste, licorice, stone,
    sardine, ice, milk, marble, lime, pea, mint, cherry, watermelon, salmon,
    banana
{
    .u-$color {
        color: var(--troika-$color);
    }
    .u-bg-$color {
        background-color: var(--troika-$color);
    }
    .u-border-$color {
        border-color: var(--troika-$color);
    }
    .u-focus-$color {
        outline-color: var(--troika-$color);
    }
}
