@import "@finn-no/troika-css-dev/tools/t-text.css";

/**


        Text helper utilities


*/
.u-word-break {
    word-wrap: break-word;
}

.u-no-break {
    white-space: nowrap;
}

.u-truncate {
    @include truncate;
}
