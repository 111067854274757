.u-border-radius {
    &-4px {
        border-radius: 4px;
    }
    &-8px {
        border-radius: 8px;
    }
    &-16px {
        border-radius: 16px;
    }
}
